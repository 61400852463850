import React, { useState, useEffect } from "react"

import SEO from '../components/seo'
import { app } from '../util/firebase'
import './index.css'
import { IndexPageWrapper, Content, CurrentStateText, InputWrapper } from '../styles/IndexPageStyles'

const IndexPage = () => {
  const [isClean, setisClean] = useState()
  const [loading, setIsLoading] = useState(true)

  useEffect(() => {
    app.database().ref('isClean').once('value').then(current => {
      setisClean(current.val());
      setIsLoading(false)
    })

    // Register a callback for when the value changes
    app.database().ref('isClean').on('value', current => {
      setisClean(current.val());
    })
  }, [])

  const toggleisClean = () => {
    setisClean(!isClean);

    // Make the change in firebase 
    app.database().ref('isClean').set(!isClean)
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <SEO title="Dishwasher" metaDescription="The dishwasher is currently ..." />
      <IndexPageWrapper isClean={isClean}>
        <Content>
          <CurrentStateText isClean={isClean}>{isClean ? 'CLEAN' : 'DIRTY'}</CurrentStateText>
          <InputWrapper>
            <input onChange={toggleisClean} id="s2" type="checkbox" className="switch" checked={isClean} />
          </InputWrapper>
        </Content>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
