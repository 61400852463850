import styled from 'styled-components'

export const IndexPageWrapper = styled.div`
  transition:  all 0.2s linear;
  background: ${({ isClean }) => isClean ? "#FFFFFF" : "#5465FF"};
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
`

export const Content = styled.div`
  margin-top: -140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CurrentStateText = styled.h3`
  font-style: italic;
  font-family: 'Montserrat', sans-serif;
  color: ${({ isClean }) => isClean ? "#5465FF" : "#FFFFFF"};
  font-size: 35px;
`

export const InputWrapper = styled.div`
  * {
    box-sizing: inherit;
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }

  input[type='checkbox'] {
    --active: #5465FF;
    --active-inner: #fff;
    --focus: 2px #5465FF;
    --border: #5465FF;
    --border-hover: #5465FF;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;

    -webkit-appearance: none;
    -moz-appearance: none;
    height: 168px;
    width: 304px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }

    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }

  input[type='checkbox'] {
    &.switch {
      border-radius: 110px;

      &:after {
        left: 22px;
        top: 24px;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }

      &:checked {
        --ab: var(--active-inner);
        --x: 136px;
      }

      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
`;